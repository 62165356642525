<template>
  <div class="contact-head">
        <h1>CONTACT US</h1>
  </div>
  <div class="contact">
    <div class="contact-info">
    
      <div class="get-container">
        <h2>Get In Touch</h2>
        <p>At Family Choice, we’re passionate about giving you a health life. Whether you have questions about our products, 
          need assistance with your order, or simply want to share your feedback, we’re here to help! Reach out to us via email at support  family-choice-.com, or give us a call at +234 8076077116. 
          You can also fill out our contact form below.
        </p>
        <div class="contact-details">
          <ul>
            <li><i class="fa-solid fa-phone"></i> 8076077116</li>
            <li><i class="fa-brands fa-whatsapp"></i> +234 8076077116</li>
            <li><i class="fa-regular fa-envelope"></i> family-choice.com</li>
            <li><i class="fa-solid fa-shop"></i>5 Olabimpe Careena Aga,ikorodu, Lagos, Nigeria,</li>
          </ul>
        </div>
      </div>

      <div class="form-container">
        <form class="contact-form">
          <div class="form-group">
    
            
               <label for="name">Name:</label>
               <input type="text" id="name" name="name" required>
          
            
          </div>
          <div class="form-group">
           <tr>
            <td><label for="email">Email:</label></td>
            <td> <input type="email" id="email" name="email" required></td>
          </tr>
          </div>
          <div class="form-group">
            <label for="subject">Subject:</label>
            <input type="text" id="subject" name="subject" required>
          </div>
          <div class="form-group">
           <tr>
             <td> <label for="message">Message:</label></td>
             <td> <textarea id="message" name="message" rows="5" required></textarea></td>
           </tr>
          </div>
          <button type="submit">Send Now</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>

.contact-head {
  text-align: center;
  color: white;
  margin-bottom: 20px;
  padding: 100px 0px;
  font-size: 60px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/contactus1.jpg');
  background-size: cover;
  background-position: center;
}

.contact{
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.contact-info {
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  display: flex;
  padding: 40px 0px;
}

.get-container {
    flex-basis: 40%;
    margin: 20px;
}

.contact-details ul{
    list-style: none;
    padding-left: 0px;
    text-align: left;
    font-size: 20px;
}

.contact-details ul li {
    padding: 10px 0px;
}

.contact-details i:hover {
    color: rgb(176, 174, 174);
}

.form-container {
    flex-basis: 50%;
    margin: 20px;
}

.form-group {
  margin-bottom: 15px;
  
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
}

.contact-form button {
  background-color:   rgb(245, 18, 124); 
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.contact-form button:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
}

</style>
