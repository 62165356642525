<template>
    <div class="lamps-header">
        <h1>Bottle Water</h1>
    </div>
    <div class="popular">

        <div class="popular-lamp">

            <router-link to="/Xiv" class="links">
                <div class="card" >
                    <img src="../assets/g.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Mini  Bottle Water</h3>
                        <p class="lamp-description">6 Pieces</p>
                        <p class="lamp-price">₦500</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xviii" class="links">
                <div class="card" >
                <img src="../assets/m.jpg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">Bottle Water</h3>
                    <p class="lamp-description">Its a Water in a Bottle its Four Pieces in a Pack</p>
                    <p class="lamp-price">₦5,000</p>
                </div>
            </div>
            </router-link>

            <router-link to="/xix" class="links">
                <div class="card" >
                    <img src="../assets/l.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">35CL Bottle Water</h3>
                        <p class="lamp-description">Its 12 Pieces in a Pack</p>
                        <p class="lamp-price">₦1,000</p>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="popular-lamp">
            <router-link to="/i" class="links">
                <div class="card">
                    <img src="../assets/12pieces.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">12 Pieces 50CL</h3>
                        <p class="lamp-description">50Cl Water</p>
                        <p class="lamp-price">₦5,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/vi" class="links">
                <div class="card" >
                    <img src="../assets/Ai4.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">75CL Bottle Water</h3>
                        <p class="lamp-description">75CL</p>
                        <p class="lamp-price">₦3,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xii" class="links">
                <div class="card" >
                    <img src="../assets/10pieces.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">10 Pieces 75CL</h3>
                        <p class="lamp-description">75cl water</p>
                        <p class="lamp-price">₦2,000</p>
                    </div>
                </div>
            </router-link>
        </div>

    </div>


</template>

<script>
    export default {
        name: "ContactPage"
    }
</script>

<style scoped>
.lamps-header {
    text-align: center;
    color: white;
    padding: 100px 0px;
    font-size: 60px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url('@/assets/sep.jpg');
    background-size: cover;
    background-position: center;
}
.popular{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    padding: 100px 0px;
}

.popular-lamp{
    display: flex;
    justify-content: center;
}

.card {
    width: 250px; 
    margin: 10px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}
</style>