<template>
    <div class="about-header">
        <h1>ABOUT US</h1>
    </div>
    <div class="about">
        <div class="text-container">
            <h1>We’re distinct by Quality</h1>
            <p>Family Chioce Purified WAter was founded in 2022 with one objective: 
                To be the best water to drink for healty living,
                and at budget_friendly prices. We offer thousands of products including Yougurt, 
                Water Dispencer and Ice Block by drinking pure quality liqid.
            </p>
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/Ai1.jpg" alt="water" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>We have a professional Productive team</h1>
                <p>Health water thrills us more than anything else. 
                    The FAmily Choice team is comprised of seasoned professionals serving residential, 
                    trade professionals, and commercial customers with premium_quality products to create a perfect aura in your interiors or exteriors. 
                    The result? With our dedicated team of professional designers, 
                    we look to maintain our high standards of quality and performance.
                </p>
            </div>
        </div>
        <div class="text">
            <h1>.</h1>
        </div>
        <div class="about-content">
            <div class="text-container">
                <h1>We value the ecosystem</h1>
                <p>We're making strides to lessen our environmental footprint.
                     That implies responsible retailing to us. 
                    To get there, we've come up with a set of targets. Such as eradicating excessive plastic from our product packaging and 
                    assuring that even the most ecologically impacting materials we use are supplied organically. 
                    Avoid nasty chemicals and contaminants that are sometimes present in other water sources. Take advantage of our natural purified water and distilled water options today.

                    Available in 50 cl / 75 cl / 1.5 Litre / 19 Litre


                </p>
            </div>
            <div class="image-container">
                <img src="../assets/about2.jpeg" alt="Man with light" width="600px" height="375px">
            </div>
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/cus.jpeg" alt="Man with light" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>We’re dedicated to our customers</h1>
                <p>At Family Choice Water, we offer an outstanding customer experience that includes a gallery of insights, 
                    inspiration, helpful content, comprehensive product description, quick service, and assistance throughout our customers' projects. 
                    Hardly anything gives us pleasure than seeing our customers embracing our products in their interior decor, 
                    from feedback on our official websites to our 80k Instagram followers.
                </p>
            </div>
        </div>
        <div class="about-content">
            <div class="text-container">
                <h1>Vision</h1>
                <p>
                    Our dream is for water to have the value it deserves on our planet, and for that value to be equally available to all living beings, now and in the future.
.
                </p>
            </div>
            <div class="text-container">
                <h1>Mission</h1>
                <p>
                    Set standards for Quality Product. 
                    To provide water supply, wastewater disposal, and water resource management to the public in a safe, reliable, environmentally sensitive, and financially responsible manner.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutPage',
    }
</script>

<style scoped>
   .about {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        color: black;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
    }
   .about-header {
        text-align: center;
        color: white;
        margin-bottom: 20px;
        padding: 100px 0px;
        font-size: 60px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/dispencer.jpeg');
        background-size: cover;
        background-position: center;
    }
   .about-content {
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 3rem;
        display: flex;
        padding: 40px 0px;
    }
   .image-container {
        flex-basis: 40%;
        margin: 20px;
    }
   .text-container {
        flex-basis: 50%;
        margin: 20px;
    }
   .image-container img {
        width: 600px;
        height: 400px;
    }
    .text h1{
        font-weight: 300;
    }
    .text-container h1 {
        font-weight: 300;
    }
    .text-container h3 {
        font-size: 30px;
    }
    .text-container p {
        font-size: 18px;
    }
</style>
