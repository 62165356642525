<template>
    <footer>
        <div class="container">
          <div class="row">

            <div class="footer-col">
              <h4>Upcoming Event:</h4>
              <ul>
                <li>Check our website for upcoming events and promotions.</li>
              </ul>
            </div>
            
            <div class="footer-col">
              <h4>How to pay:</h4>
              <ul>
                <li>We accept cash,  credit cards, and mobile payments.</li>
              </ul>
            </div>

            <div class="footer-col">
              <h4>Opening Hours:</h4>
              <ul>
                <li>Monday - Friday</li>
                <h4>24hrs online order</h4>
              </ul>
            </div>

            <div class="footer-col">
              <h4>Contact Us With:</h4>
              <ul>
                <i class="fa-brands fa-facebook"></i>
                <i class="fa-brands fa-twitter"></i>
                <i class="fa-brands fa-instagram"></i>
                <i class="fa-solid fa-globe"></i>
              </ul>
            </div>
            
            <div class="footer-col">
              <h4>Categories:</h4>
              <ul>
                <li><router-link to="" class="links">Bottle WAter</router-link></li>
                <li><router-link to="" class="links">Water Dispencer</router-link></li>
                <li><router-link to="" class="links">Ice Block</router-link></li>
                <li><router-link to="" class="links">Yougurt</router-link></li>
              </ul>
            </div>

            <div class="footer-col">
              <h4>Contact Info:</h4>
              <ul>
                <li><i class="fa-solid fa-phone"></i>0076077116</li>
                <li><i class="fa-regular fa-envelope"></i>family-choice_store.com</li>
                <li><i class="fa-solid fa-location-dot"></i>5 Olabimpe Careena Aga,ikorodu, Lagos, Nigeria,</li>
              </ul>
            </div>

          </div>
        </div>
        <p> &copy; 2023 Family Choice Water. All rights reserved.</p>
    </footer>
</template>

<script>
export default {
    name: 'FooterPage',
}
</script>

<style scoped>

footer .container{
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: center;
}

footer{
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; */
    font-family: 'Montserrat', sans-serif;
    padding: 70px 0px;
    background-color: rgb(245, 18, 124);
    color: white;
}

footer .row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.links {
    text-decoration: none;
    color: white;
    font-size: 14px;
}

ul :hover{
  color: #fff;
  cursor: pointer;
}

.footer-col{
    width: 33.33%;
    padding: 0px 15px;
    box-sizing: border-box;
    padding-left: 35px;

    @media (max-width: 768px) {
    width: 100%; /* Full width on screens below 768px */
  }
}

.footer-col i{
    cursor: pointer;
    padding-right: 10px;
}

footer h4{
    margin-bottom: 5px;
    position: relative;
    color: #fff;
    text-align: left;
}

footer ul{
    list-style: none;
    padding-left: 0px;
    text-align: left;
    font-size: 14px;
}

.footer i :hover {
    color: rgb(176, 174, 174);
}

footer p{
    text-align: center;
    color:#fff;
}
</style>