<template>
    <div class="lamps-header">
        <h1>OUR PRODUCTS</h1>
    </div>
    <div class="popular">

        <div class="popular-lamp">

            <router-link to="/i" class="links">
                <div class="card">
                    <img src="../assets/12pieces.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">12 Pieces 50CL</h3>
                        <p class="lamp-description">50cl</p>
                        <p class="lamp-price">₦5,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/ii" class="links">
                <div class="card" >
                    <img src="../assets/yougurtf.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Colo Ice Cream</h3>
                        <p class="lamp-description">mini bottle</p>
                        <p class="lamp-price">₦10,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iii" class="links">
                <div class="card" >
                    <img src="../assets/h.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Dispencer Bottle Water</h3>
                        <p class="lamp-description">dispencer water</p>
                        <p class="lamp-price">₦20,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iv" class="links">
                <div class="card" >
                    <img src="../assets/l.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">35CL Bottle Water</h3>
                        <p class="lamp-description">35cl bottle water</p>
                        <p class="lamp-price">₦1,000</p>
             </div>
                </div>
            </router-link>

            <router-link to="/ix" class="links">
                <div class="card" >
                    <img src="../assets/i.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Ice Cream </h3>
                        <p class="lamp-description">plastic ice cream</p>
                        <p class="lamp-price">₦3,500</p>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="popular-lamp">

            <router-link to="/xviii" class="links">
                <div class="card" >
                <img src="../assets/m.jpg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">Bottle Water</h3>
                    <p class="lamp-description">its a water in a bottle four pieces in a park</p>
                    <p class="lamp-price">₦5,000</p>
                </div>
            </div>
            </router-link>
            
            <router-link to="/vi" class="links">
                <div class="card" >
                    <img src="../assets/Ai4.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">75CL Bottle Water</h3>
                        <p class="lamp-description">75cl</p>
                        <p class="lamp-price">₦3,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/vii" class="links">
                <div class="card" >
                    <img src="../assets/g.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">45Cl 6 Pieces Pack</h3>
                        <p class="lamp-description"> pieces in a pack</p>
                        <p class="lamp-price">₦800</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/viii" class="links">
                <div class="card" >
                    <img src="../assets/milk.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Bull Milk </h3>
                        <p class="lamp-description">milk in a 2litter keg</p>
                        <p class="lamp-price">₦20,000</p>
                    </div>
                </div>
            </router-link>
            
            <router-link to="/X" class="links">
                <div class="card" >
                    <img src="../assets/sing.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Jobo Ice Cream </h3>
                        <p class="lamp-description">ice cream</p>
                        <p class="lamp-price">₦5,000</p>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="popular-lamp">

            <router-link to="/xi" class="links">
                <div class="card">
                    <img src="../assets/disp3.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">2 Pieces 150CL Dispencer Water</h3>
                        <p class="lamp-description">150cl dispencer water</p>
                        <p class="lamp-price">₦30,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xii" class="links">
                <div class="card" >
                    <img src="../assets/10pieces.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">75CL Bottle Water</h3>
                        <p class="lamp-description">bottle water 75cl</p>
                        <p class="lamp-price">₦5,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xiii" class="links">
                <div class="card" >
                    <img src="../assets/block.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Brick Ice Block</h3>
                        <p class="lamp-description">brick ice block</p>
                        <p class="lamp-price">₦55,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/Xiv" class="links">
                <div class="card" >
                    <img src="../assets/f.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Mini Bottle Water</h3>
                        <p class="lamp-description">1 piece if mini bottle water</p>
                        <p class="lamp-price">₦100</p>
                    </div>
                </div>
            </router-link>
            
            <router-link to="/xvii" class="links">
                <div class="card" >
                    <img src="../assets/k.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">45CL Bottle Water </h3>
                        <p class="lamp-description">1 piece 45cl  bottle water</p>
                        <p class="lamp-price">₦150</p>
                    </div>
                </div>
            </router-link>

        </div>

        <div class="popular-lamp">

            <router-link to="/xviii" class="links">
                <div class="card" >
                <img src="../assets/famblock2.jpg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">Ice Cube</h3>
                    <p class="lamp-description">party ice cube</p>
                    <p class="lamp-price">₦3,000</p>
                </div>
            </div>
            </router-link>
            
            <router-link to="/xix" class="links">
                <div class="card" >
                    <img src="../assets/tt.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Bottle Yougurt</h3>
                        <p class="lamp-description">yougurt bottle</p>
                        <p class="lamp-price">₦5,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xx" class="links">
                <div class="card" >
                    <img src="../assets/A.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">55CL Bottle Water</h3>
                        <p class="lamp-description">1pieces of bottle water</p>
                        <p class="lamp-price">₦200</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xvi" class="links">
                <div class="card" >
                    <img src="../assets/1pieces.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">75CL Bottle Water </h3>
                        <p class="lamp-description">table water 75cl bottle 1piece</p>
                        <p class="lamp-price">₦500</p>
                    </div>
                </div>
            </router-link>
            
            <router-link to="/xv" class="links">
                <div class="card" >
                    <img src="../assets/disp5.jpeg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Dispencer Water 200CL </h3>
                        <p class="lamp-description">200cl dispencer water</p>
                        <p class="lamp-price">₦10,000</p>
                    </div>
                </div>
            </router-link>
        </div>

    </div>


</template>

<script>
    export default {
        name: "ContactPage"
    }
</script>

<style scoped>
.lamps-header {
    text-align: center;
    color: white;
    padding: 100px 0px;
    font-size: 60px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url('@/assets/sep.jpg');
    background-size: cover;
    background-position: center;
}
.popular{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    padding: 100px 0px;
}

.popular-lamp{
    display: flex;
    justify-content: center;
}

.card {
    width: 250px; 
    margin: 10px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}
</style>