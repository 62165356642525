<template>
    <div class="popular">

        <h2>BEST SELLERS</h2>
        <div class="popular-lamp">
            <router-link to="/i" class="links">
                <div class="card">
                    <img src="../assets/12pieces.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">12 Pieces 50cl</h3>
                        <p class="lamp-description">Bottle Water</p>
                        <p class="lamp-price">5,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/ii" class="links">
                <div class="card" >
                    <img src="../assets/yougurtf.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Colo Ice Cream</h3>
                        <p class="lamp-description">Yougurt</p>
                        <p class="lamp-price">₦10,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iii" class="links">
                <div class="card" >
                    <img src="../assets/h.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Dispencer Bottle Water</h3>
                        <p class="lamp-description">Dispencer Water</p>
                        <p class="lamp-price">₦20,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iv" class="links">
                <div class="card" >
                    <img src="../assets/l.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">35Cl Bottle Water</h3>
                        <p class="lamp-description">Mini Water</p>
                        <p class="lamp-price">₦1,000</p>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="popular-lamp">

            <router-link to="/v" class="links">
                <div class="card" >
                <img src="../assets/i.jpg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">Ice Cream</h3>
                    <p class="lamp-description">Vanillar</p>
                    <p class="lamp-price">₦3,500</p>
                </div>
            </div>
            </router-link>
            
            <router-link to="/vi" class="links">
                <div class="card" >
                    <img src="../assets/d.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Party Ice Block</h3>
                        <p class="lamp-description">Ice Block</p>
                        <p class="lamp-price">₦10,000</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/vii" class="links">
                <div class="card" >
                    <img src="../assets/g.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">45Cl  Bottle Water </h3>
                        <p class="lamp-description">6 Pieces</p>
                        <p class="lamp-price">₦800</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/viii" class="links">
                <div class="card" >
                    <img src="../assets/milk.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">Bull Milk</h3>
                        <p class="lamp-description">its in 2 Littres Bottle</p>
                        <p class="lamp-price">₦20,000</p>
                    </div>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'PopularPage',
    }
</script>

<style scoped>
.popular{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    padding: 100px 0px;
}

.popular h2{
    color: black;
    padding: 0px 0px;
    font-family: 'Montserrat', sans-serif;
}

.popular-lamp{
    display: flex;
    justify-content: center;
}

.card {
    width: 250px; 
    margin: 10px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}

@media (max-width: 768px) {
  .popular-lamp {
    flex-wrap: wrap; /* Wrap cards to stack vertically */
    justify-content: center; /* Center the cards horizontally */
  }

  .card {
    margin: 10px 0; /* Adjust margin for vertical stacking */
  }
}


</style>