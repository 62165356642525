<template>
  <div class="cart-container">
    <h2>Your Cart</h2>
    <ul v-if="cartItems.length">
      <li v-for="(item, index) in cartItems" :key="item.id">
        <div class="cart-item">
          <img :src="item.imageUrl" alt="Product Image" class="product-image" />
          <div class="item-details">
            <h3>{{ item.name }}</h3>
            <p v-if="item.size">Size: {{ item.size }}</p>
            <div v-if="item.sizes && item.sizes.length > 1">
              <label for="size-select">Select Size:</label>
              <select v-model="item.size" id="size-select">
                <option v-for="size in item.sizes" :key="size">{{ size }}</option>
              </select>
            </div>
            <p>Price: {{ item.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}</p>
            <div class="quantity-control">
              <button @click="decreaseQuantity(index)">-</button>
              <span>{{ item.quantity }}</span>
              <button @click="increaseQuantity(index)">+</button>
            </div>
            <button @click="removeItem(index)" class="remove-button">Remove</button>
          </div>
        </div>
      </li>
    </ul>
    <p v-else class="empty-cart-message">Your cart is empty.</p>
    <div class="cart-summary" v-if="cartItems.length">
      <p>Subtotal: {{ calculateSubtotal() }}</p>
      <button @click="handleOrderNow" class="checkout-button">Checkout</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cartItems: [],
    };
  },
  methods: {
    increaseQuantity(index) {
      this.cartItems[index].quantity++;
    },
    decreaseQuantity(index) {
      if (this.cartItems[index].quantity > 1) {
        this.cartItems[index].quantity--;
      }
    },
    removeItem(index) {
      this.cartItems.splice(index, 1);
    },
    calculateSubtotal() {
      let total = 0;
      for (const item of this.cartItems) {
        total += item.price * item.quantity;
      }
      return total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    handleOrderNow() {
      // Implement logic to handle order submission (e.g., navigate to checkout page, send data to server)
      alert("Order submitted (placeholder)");
    },
  },
};
</script>

<style scoped>
.cart-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.product-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.item-details {
  flex-grow: 1;
}

.remove-button {
  border: none;
  background: none;
  color: rgb(12, 23, 150);
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.empty-cart-message {
  text-align: center;
  padding: 20px;
}

.cart-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.checkout-button {
  background-color: #007bff;
  color: white;
  border: none
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-control button {
  border: none;
  background: none;
  color: #999;
  cursor: pointer;
  padding: 0 5px;
}

.quantity-control span {
  margin-left: 5px;
}
</style>